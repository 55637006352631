// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-arquitecturas-de-la-imaginacion-index-js": () => import("./../../../src/components/arquitecturas_de_la_imaginacion/index.js" /* webpackChunkName: "component---src-components-arquitecturas-de-la-imaginacion-index-js" */),
  "component---src-components-blog-episode-js": () => import("./../../../src/components/blog/episode.js" /* webpackChunkName: "component---src-components-blog-episode-js" */),
  "component---src-components-blog-event-js": () => import("./../../../src/components/blog/event.js" /* webpackChunkName: "component---src-components-blog-event-js" */),
  "component---src-components-blog-index-js": () => import("./../../../src/components/blog/index.js" /* webpackChunkName: "component---src-components-blog-index-js" */),
  "component---src-components-blog-news-js": () => import("./../../../src/components/blog/news.js" /* webpackChunkName: "component---src-components-blog-news-js" */),
  "component---src-components-blog-program-js": () => import("./../../../src/components/blog/program.js" /* webpackChunkName: "component---src-components-blog-program-js" */),
  "component---src-components-colorsentimiento-js": () => import("./../../../src/components/colorsentimiento.js" /* webpackChunkName: "component---src-components-colorsentimiento-js" */),
  "component---src-components-criollos-index-js": () => import("./../../../src/components/criollos/index.js" /* webpackChunkName: "component---src-components-criollos-index-js" */),
  "component---src-components-monkey-monkey-js": () => import("./../../../src/components/monkey/monkey.js" /* webpackChunkName: "component---src-components-monkey-monkey-js" */),
  "component---src-components-nueva-arquitectura-img-2022-2-cuarto-js": () => import("./../../../src/components/nueva_arquitectura_img-2022-2/cuarto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2022-2-cuarto-js" */),
  "component---src-components-nueva-arquitectura-img-2022-2-index-js": () => import("./../../../src/components/nueva_arquitectura_img-2022-2/index.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2022-2-index-js" */),
  "component---src-components-nueva-arquitectura-img-2022-2-nivelacion-js": () => import("./../../../src/components/nueva_arquitectura_img-2022-2/nivelacion.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2022-2-nivelacion-js" */),
  "component---src-components-nueva-arquitectura-img-2022-2-primer-js": () => import("./../../../src/components/nueva_arquitectura_img-2022-2/primer.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2022-2-primer-js" */),
  "component---src-components-nueva-arquitectura-img-2022-2-quinto-js": () => import("./../../../src/components/nueva_arquitectura_img-2022-2/quinto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2022-2-quinto-js" */),
  "component---src-components-nueva-arquitectura-img-2022-2-segundo-js": () => import("./../../../src/components/nueva_arquitectura_img-2022-2/segundo.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2022-2-segundo-js" */),
  "component---src-components-nueva-arquitectura-img-2022-2-tercero-js": () => import("./../../../src/components/nueva_arquitectura_img-2022-2/tercero.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2022-2-tercero-js" */),
  "component---src-components-nueva-arquitectura-img-2023-2-cuarto-js": () => import("./../../../src/components/nueva_arquitectura_img-2023-2/cuarto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2023-2-cuarto-js" */),
  "component---src-components-nueva-arquitectura-img-2023-2-index-js": () => import("./../../../src/components/nueva_arquitectura_img-2023-2/index.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2023-2-index-js" */),
  "component---src-components-nueva-arquitectura-img-2023-2-nivelacion-js": () => import("./../../../src/components/nueva_arquitectura_img-2023-2/nivelacion.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2023-2-nivelacion-js" */),
  "component---src-components-nueva-arquitectura-img-2023-2-primer-js": () => import("./../../../src/components/nueva_arquitectura_img-2023-2/primer.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2023-2-primer-js" */),
  "component---src-components-nueva-arquitectura-img-2023-2-quinto-js": () => import("./../../../src/components/nueva_arquitectura_img-2023-2/quinto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2023-2-quinto-js" */),
  "component---src-components-nueva-arquitectura-img-2023-2-segundo-js": () => import("./../../../src/components/nueva_arquitectura_img-2023-2/segundo.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2023-2-segundo-js" */),
  "component---src-components-nueva-arquitectura-img-2023-2-tercero-js": () => import("./../../../src/components/nueva_arquitectura_img-2023-2/tercero.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2023-2-tercero-js" */),
  "component---src-components-nueva-arquitectura-img-2024-1-cuarto-js": () => import("./../../../src/components/nueva_arquitectura_img-2024-1/cuarto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2024-1-cuarto-js" */),
  "component---src-components-nueva-arquitectura-img-2024-1-index-js": () => import("./../../../src/components/nueva_arquitectura_img-2024-1/index.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2024-1-index-js" */),
  "component---src-components-nueva-arquitectura-img-2024-1-nivelacion-js": () => import("./../../../src/components/nueva_arquitectura_img-2024-1/nivelacion.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2024-1-nivelacion-js" */),
  "component---src-components-nueva-arquitectura-img-2024-1-primer-js": () => import("./../../../src/components/nueva_arquitectura_img-2024-1/primer.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2024-1-primer-js" */),
  "component---src-components-nueva-arquitectura-img-2024-1-quinto-js": () => import("./../../../src/components/nueva_arquitectura_img-2024-1/quinto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2024-1-quinto-js" */),
  "component---src-components-nueva-arquitectura-img-2024-1-segundo-js": () => import("./../../../src/components/nueva_arquitectura_img-2024-1/segundo.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2024-1-segundo-js" */),
  "component---src-components-nueva-arquitectura-img-2024-1-tercero-js": () => import("./../../../src/components/nueva_arquitectura_img-2024-1/tercero.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-2024-1-tercero-js" */),
  "component---src-components-nueva-arquitectura-img-cuarto-js": () => import("./../../../src/components/nueva_arquitectura_img/cuarto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-cuarto-js" */),
  "component---src-components-nueva-arquitectura-img-index-js": () => import("./../../../src/components/nueva_arquitectura_img/index.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-index-js" */),
  "component---src-components-nueva-arquitectura-img-nivelacion-js": () => import("./../../../src/components/nueva_arquitectura_img/nivelacion.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-nivelacion-js" */),
  "component---src-components-nueva-arquitectura-img-primer-js": () => import("./../../../src/components/nueva_arquitectura_img/primer.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-primer-js" */),
  "component---src-components-nueva-arquitectura-img-quinto-js": () => import("./../../../src/components/nueva_arquitectura_img/quinto.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-quinto-js" */),
  "component---src-components-nueva-arquitectura-img-segundo-js": () => import("./../../../src/components/nueva_arquitectura_img/segundo.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-segundo-js" */),
  "component---src-components-nueva-arquitectura-img-tercero-js": () => import("./../../../src/components/nueva_arquitectura_img/tercero.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-img-tercero-js" */),
  "component---src-components-nueva-arquitectura-index-js": () => import("./../../../src/components/nueva_arquitectura/index.js" /* webpackChunkName: "component---src-components-nueva-arquitectura-index-js" */),
  "component---src-components-sonidos-audiovisuales-artists-emilio-js": () => import("./../../../src/components/sonidos_audiovisuales/artists/emilio.js" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-artists-emilio-js" */),
  "component---src-components-sonidos-audiovisuales-artists-jonathan-js": () => import("./../../../src/components/sonidos_audiovisuales/artists/jonathan.js" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-artists-jonathan-js" */),
  "component---src-components-sonidos-audiovisuales-artists-lucia-js": () => import("./../../../src/components/sonidos_audiovisuales/artists/lucia.js" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-artists-lucia-js" */),
  "component---src-components-sonidos-audiovisuales-artists-paola-js": () => import("./../../../src/components/sonidos_audiovisuales/artists/paola.js" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-artists-paola-js" */),
  "component---src-components-sonidos-audiovisuales-artists-sanz-js": () => import("./../../../src/components/sonidos_audiovisuales/artists/sanz.js" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-artists-sanz-js" */),
  "component---src-components-sonidos-audiovisuales-artists-vered-js": () => import("./../../../src/components/sonidos_audiovisuales/artists/vered.js" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-artists-vered-js" */),
  "component---src-components-sonidos-audiovisuales-credits-credit-jsx": () => import("./../../../src/components/sonidos_audiovisuales/credits/credit.jsx" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-credits-credit-jsx" */),
  "component---src-components-sonidos-audiovisuales-index-js": () => import("./../../../src/components/sonidos_audiovisuales/index.js" /* webpackChunkName: "component---src-components-sonidos-audiovisuales-index-js" */),
  "component---src-components-template-1-exposition-3-slider-js": () => import("./../../../src/components/template_1/exposition3slider.js" /* webpackChunkName: "component---src-components-template-1-exposition-3-slider-js" */),
  "component---src-components-template-1-slider-type-2-template-js": () => import("./../../../src/components/template_1/SliderType2Template.js" /* webpackChunkName: "component---src-components-template-1-slider-type-2-template-js" */),
  "component---src-components-template-1-template-1-part-1-js": () => import("./../../../src/components/template_1/Template1part1.js" /* webpackChunkName: "component---src-components-template-1-template-1-part-1-js" */),
  "component---src-components-template-2-template-2-js": () => import("./../../../src/components/template_2/Template_2.js" /* webpackChunkName: "component---src-components-template-2-template-2-js" */),
  "component---src-components-template-3-template-3-part-1-js": () => import("./../../../src/components/template_3/Template3part1.js" /* webpackChunkName: "component---src-components-template-3-template-3-part-1-js" */),
  "component---src-components-template-3-template-3-part-2-js": () => import("./../../../src/components/template_3/Template3part2.js" /* webpackChunkName: "component---src-components-template-3-template-3-part-2-js" */),
  "component---src-components-template-3-template-3-part-3-js": () => import("./../../../src/components/template_3/Template3part3.js" /* webpackChunkName: "component---src-components-template-3-template-3-part-3-js" */),
  "component---src-components-template-4-template-4-js": () => import("./../../../src/components/template_4/Template4.js" /* webpackChunkName: "component---src-components-template-4-template-4-js" */),
  "component---src-components-template-5-template-5-js": () => import("./../../../src/components/template_5/Template5.js" /* webpackChunkName: "component---src-components-template-5-template-5-js" */),
  "component---src-components-template-6-template-6-js": () => import("./../../../src/components/template_6/Template6.js" /* webpackChunkName: "component---src-components-template-6-template-6-js" */),
  "component---src-components-yuyachkani-index-js": () => import("./../../../src/components/yuyachkani/index.js" /* webpackChunkName: "component---src-components-yuyachkani-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-agendacultural-js": () => import("./../../../src/pages/agendacultural.js" /* webpackChunkName: "component---src-pages-agendacultural-js" */),
  "component---src-pages-alianzas-js": () => import("./../../../src/pages/alianzas.js" /* webpackChunkName: "component---src-pages-alianzas-js" */),
  "component---src-pages-artesaudiovisuales-js": () => import("./../../../src/pages/artesaudiovisuales.js" /* webpackChunkName: "component---src-pages-artesaudiovisuales-js" */),
  "component---src-pages-artesescenicas-js": () => import("./../../../src/pages/artesescenicas.js" /* webpackChunkName: "component---src-pages-artesescenicas-js" */),
  "component---src-pages-artesplasticas-js": () => import("./../../../src/pages/artesplasticas.js" /* webpackChunkName: "component---src-pages-artesplasticas-js" */),
  "component---src-pages-arteycultura-js": () => import("./../../../src/pages/arteycultura.js" /* webpackChunkName: "component---src-pages-arteycultura-js" */),
  "component---src-pages-arteyculturademo-js": () => import("./../../../src/pages/arteyculturademo.js" /* webpackChunkName: "component---src-pages-arteyculturademo-js" */),
  "component---src-pages-disenoyartesvisuales-js": () => import("./../../../src/pages/disenoyartesvisuales.js" /* webpackChunkName: "component---src-pages-disenoyartesvisuales-js" */),
  "component---src-pages-enexhibicion-js": () => import("./../../../src/pages/enexhibicion.js" /* webpackChunkName: "component---src-pages-enexhibicion-js" */),
  "component---src-pages-exhibiciones-js": () => import("./../../../src/pages/exhibiciones.js" /* webpackChunkName: "component---src-pages-exhibiciones-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-org-js": () => import("./../../../src/pages/index-org.js" /* webpackChunkName: "component---src-pages-index-org-js" */),
  "component---src-pages-literaturaypublicaciones-js": () => import("./../../../src/pages/literaturaypublicaciones.js" /* webpackChunkName: "component---src-pages-literaturaypublicaciones-js" */),
  "component---src-pages-musicaycreacionsonora-js": () => import("./../../../src/pages/musicaycreacionsonora.js" /* webpackChunkName: "component---src-pages-musicaycreacionsonora-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-noticias-artes-escenicas-js": () => import("./../../../src/pages/noticias/artes-escenicas.js" /* webpackChunkName: "component---src-pages-noticias-artes-escenicas-js" */),
  "component---src-pages-noticias-artes-plasticas-js": () => import("./../../../src/pages/noticias/artes-plasticas.js" /* webpackChunkName: "component---src-pages-noticias-artes-plasticas-js" */),
  "component---src-pages-noticias-artes-visuales-js": () => import("./../../../src/pages/noticias/artes-visuales.js" /* webpackChunkName: "component---src-pages-noticias-artes-visuales-js" */),
  "component---src-pages-noticias-audiovisuales-js": () => import("./../../../src/pages/noticias/audiovisuales.js" /* webpackChunkName: "component---src-pages-noticias-audiovisuales-js" */),
  "component---src-pages-noticias-diseno-js": () => import("./../../../src/pages/noticias/diseno.js" /* webpackChunkName: "component---src-pages-noticias-diseno-js" */),
  "component---src-pages-noticias-index-js": () => import("./../../../src/pages/noticias/index.js" /* webpackChunkName: "component---src-pages-noticias-index-js" */),
  "component---src-pages-noticias-literatura-js": () => import("./../../../src/pages/noticias/literatura.js" /* webpackChunkName: "component---src-pages-noticias-literatura-js" */),
  "component---src-pages-noticias-musica-js": () => import("./../../../src/pages/noticias/musica.js" /* webpackChunkName: "component---src-pages-noticias-musica-js" */),
  "component---src-pages-noticias-patrimonio-js": () => import("./../../../src/pages/noticias/patrimonio.js" /* webpackChunkName: "component---src-pages-noticias-patrimonio-js" */),
  "component---src-pages-noticias-quienes-somos-js": () => import("./../../../src/pages/noticias/quienes-somos.js" /* webpackChunkName: "component---src-pages-noticias-quienes-somos-js" */),
  "component---src-pages-noticias-sello-upc-js": () => import("./../../../src/pages/noticias/sello-upc.js" /* webpackChunkName: "component---src-pages-noticias-sello-upc-js" */),
  "component---src-pages-noticias-upc-cultural-js": () => import("./../../../src/pages/noticias/upc-cultural.js" /* webpackChunkName: "component---src-pages-noticias-upc-cultural-js" */),
  "component---src-pages-patrimonio-js": () => import("./../../../src/pages/patrimonio.js" /* webpackChunkName: "component---src-pages-patrimonio-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-programas-js": () => import("./../../../src/pages/programas.js" /* webpackChunkName: "component---src-pages-programas-js" */),
  "component---src-pages-quienesomos-js": () => import("./../../../src/pages/quienesomos.js" /* webpackChunkName: "component---src-pages-quienesomos-js" */),
  "component---src-pages-sellobicentenario-js": () => import("./../../../src/pages/sellobicentenario.js" /* webpackChunkName: "component---src-pages-sellobicentenario-js" */),
  "component---src-pages-selloupc-js": () => import("./../../../src/pages/selloupc.js" /* webpackChunkName: "component---src-pages-selloupc-js" */),
  "component---src-pages-upccultural-js": () => import("./../../../src/pages/upccultural.js" /* webpackChunkName: "component---src-pages-upccultural-js" */)
}

